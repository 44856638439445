.dropdown_container {
  position: relative;
  display: inline-block;
}

.verticalDots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border: 1px solid var(--input-border-color);
  color: var(--input-border-color);
  transition: all 0.3s ease;
}

.verticalDots:hover {
  border: 1px solid #377cf6;
  background-color: #e5efff;
  transform: scale(1.05);
}
.dropdownMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 45px;
  right: 0;
  background-color: white;
  border: 1px solid var(--input-border-color);
  box-shadow: 0px 4px 10px rgba(43, 42, 42, 0.3);
  z-index: 100;
  width: 175px;
  border-radius: 8px;
  padding: 4px;
}

.dropdownItemAll {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}

.dropdownItem {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}

.dropdownItem:hover {
  background-color: #d6e5ff;
}

.selected {
  background-color: #377cf6;
  color: #ffffff;
}
