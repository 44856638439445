.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 20;
    width: 100%; 
    background-color: #F3F2F9;
    padding: 1rem 2rem;
  }
  
  .menuContainer {
    flex-grow: 1;
  }
  .midElements{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
  
  .menuItems {
    display: flex;
    /* justify-content: space-around; */
    gap: 6px;
    align-items: center;
    list-style-type: none; 
    margin: 0;
    padding: 0;
  }
  
  .menuItems li {
    padding: 7px 13px;
    font-size: 14px;
    font-weight: 500;
    color: #2A2626;
    cursor: pointer;
    transition: all 150ms ease;
  }
  
  .menuItems li:hover {
    background-color: rgba(42, 38, 38, 0.2); 
    color: #FFFF;
    border-radius: 24px;
  }
  
  .menuItems li.active{
    background-color: #2A2626; 
    color: #FFFF;
    border-radius: 24px;
  }
  
  .headerLast{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  
  .iconContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFF;
    padding: 6px;
    border-radius: 50px;
    cursor: pointer;
    transition: all .3s ease;
  }
  
  .iconContainer:hover{
    transform: scale(1.05);
  }
  
  .iconContainer svg{
    color: #434343;
    transform: scaleX(-1) rotate(-35deg);
  }
  
  .iconContainer{
    transition: all 0.3s ease;
  }
  
  .iconContainer:hover{
    transform: scale(1.05);
    background-color: #D5E4FF;
  }
  .header :global(.select__control:hover .select__placeholder) {
    color: #fff !important;
  }
  
  .header :global(.select__control:hover .select__dropdown-indicator) {
    color: #fff !important;
  }
  
  .header :global(.select__control:hover .select__single-value) {
    color: #fff !important;
  }

