.survey_wrapper {
  border: 1px solid #e9e9e9;
  border-radius: 12px;
  padding-inline: 12px;
  margin-bottom: 16px;
}
.survey_wrapper:last-child {
  margin-bottom: 0;
}

.survey_detail {
  padding-top: 11px;
  padding-bottom: 21px;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
}
.customer_grid_info_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 11px;
  overflow: hidden;
  transition: all 500ms;
}
.bg_orange {
  background-color: #ffece2;
  color: #ee824d;
}
.survey_form_footer {
  padding-block: 12px;
}

.submit_btn {
  padding: 10px 36px;
  font-size: 12px;
  border-radius: 4px;
  border: none;
}

.primary_btn {
  background-color: #4372e9;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.primary_btn:hover {
  background-color: #0044f1;
  transform: scale(1.06);
}

.secondary_btn {
  border: 1px solid #9c9c9c !important;
  color: #9c9c9c;
  background-color: #fff;
  margin-left: 13px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.secondary_btn:hover {
  background-color: #fbfbfb !important;
  transform: scale(1.06) !important;
}


@media only screen and (max-width: 450px) {
  .submit_btn{
    font-weight: 500;
    width: 83px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

