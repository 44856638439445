.close_btn {
  border: none;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  cursor: pointer;
}

.close_btn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}
.videoName {
  color: black;
}

.video_title {
  color: #fff;
}

.title_wrapper {
  padding-inline: 0.5rem;
}

/* VideoPlayer.module.css */
.container {
  position: relative;
  width: 100%;
  margin: 0 auto; /* mx-auto */
  background-color: black;
  border-radius: 12px;
  overflow: hidden;
}

.video {
  width: 100%;
}

.bufferingOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* bg-opacity-50 */
}

.spinner {
  animation: spin 1s linear infinite;
  border-radius: 9999px;
  height: 4rem; /* h-16 */
  width: 4rem; /* w-16 */
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.tooltip {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  z-index: 1000;
}
.controlsContainer {
  background: #000; /* bg-gradient-to-t */
  padding-inline: 0.5rem;
}

.seekBar {
  width: 100%;
  height: 3px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: all 500ms;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.flexCenter {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* space-x-2 */
}

.button {
  padding: 0.5rem; /* p-2 */
  font-size: 1.25rem; /* text-xl */
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  line-height: 1;
}

.button img {
  filter: invert();
  width: 24px;
}

.timeDisplay {
  white-space: nowrap;
  font-size: 14px;
}

.volumeRange {
  width: 5rem; /* w-20 */
  height: 5px; /* h-2 */
  background: transparent;
  cursor: pointer;
  appearance: none;
  border-radius: 3px;
}

.loader_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 450px) {
  .flexCenter {
    gap: 2px;
  }

  .volumeRange {
    width: 3rem;
  }
  .video_title {
    font-size: 12px;
  }
}
