.DigitalInput {
  display: flex;
  justify-content: center;
  height: 100%;
  padding-top: 9px;
  padding-bottom: 8px;
}

.DigitalInput input {
  width: 163px;
  height: 40px;
  color: #333;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-right: 19px;
  border: 1px solid slategray;
}

.TimeDisplay {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .DigitalInput input {
    width: 150px;
    height: 45px;
    color: #333;
    border: 2px solid #007bff;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 15px;
  }
}