.container {
  background-color: #2a2626;
  color: #ffff;
  padding: 0px 0px 16px 16px;
  border-radius: 26px;
  height: calc(100vh - 112px);
  overflow-y: hidden;
}

.wrapperBox::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}
.wrapperBox::-webkit-scrollbar {
  display: initial;
}

.wrapperBox::-webkit-scrollbar-thumb {
  background-color: #377cf6;
  border-radius: 28px;
}

.wrapperBox::-webkit-scrollbar-button {
  display: initial;
}

.wrapperBox::-webkit-scrollbar {
  -ms-overflow-style: initial;
  scrollbar-width: thin;
}
.wrapperBox::-webkit-scrollbar-corner {
  background-color: transparent;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px;
  margin-right: 1.2rem;


}

.searchBox{
  margin-right: 1.2rem;
}
.searchBox input {
  all: unset;
  width: 86%;
  background-color: #39393f;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 400;
  padding: 8px 16px;
}
.searchBox input::placeholder {
  color: #bababa;
}

.content_one {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #bbbbbb;
}

.content_two {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #bbbbbb;
}

.content_three {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
  color: #bbbbbb;
}

.dataBoxWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dataBox {
  color: #bbbb;
  background-color: #39393f;
  border-radius: 18px;
  padding: 20px 18px;
  margin-right: 16px;
}

.headingName {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.dataBox:hover {
  background-color: #377cf6;
  color: #ffff;
}

.dataBox:hover .content_one,
.dataBox:hover .content_two,
.dataBox:hover .content_three {
  color: var(--white-color);
  cursor: pointer;
}

.headerWrapper{
  position: sticky;
  top: 0;
  background: #2a2626;
  padding-bottom: 20px;
}


.wrapperBox{
  height: calc(100vh - 240px);
  overflow: hidden;
  overflow-y: scroll;
}