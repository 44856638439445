.pending_card_wrapper {
  border-radius: 16px;
  background-color: #fff;
  padding: 19px 28px;
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.pendingQueHead {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pending-box-container {
  padding: 0.6rem 1rem !important;
}

.grid_3 {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.grid_2 {
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
}
.pending_card {
  padding: 5px;
  background-color: #f6f6f6;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.5s;
}
.disabled_card {
  border: 1px solid #d9d9d9 !important;
  cursor: not-allowed;
}
.disabled_card .pending_stats,
.disabled_card .pending_card_desc {
  color: #d9d9d9 !important;
}
.disabled_card .pending_card_title {
  color: #c0bfbf !important;
}
.deco-text:hover {
  text-decoration: underline;
  color: #000;
}
.pending_card_inner {
  border: 1px solid #eba900;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 8px;
  padding: 28px 21px;
  height: 107px;
  transition: all 0.5s;
}
.pending_card_hover:hover {
  background-color: rgba(235, 169, 0, 0.3);
  transition: all 0.5s;
  border: 1px solid transparent;
}
.pending_card_hover:hover .pending_stats {
  color: var(--input-border-color) !important;
}
.pending_stats {
  font-size: 2rem;
  font-weight: 600;
  color: #eba900;
}
.pending_card_title {
  font-size: 18px;
  font-weight: 600;
  color: var(--input-border-color);
}
.pending_card_desc {
  font-size: 12px;
  margin-top: 4px;
  color: var(--input-border-color);
}
.active_card {
  background-color: #eba900;
  gap: 1rem;
}
.mandatory {
  position: absolute;
  top: 4px;
  right: 3px;
  color: #e14514;
}
.active_card .pending_stats,
.active_card .pending_card_title,
.active_card .pending_card_desc {
  color: #fff;
}

.pending_queue_title {
  font-weight: 600;
  font-size: 20px;
}
.no_hover:hover {
  text-decoration: none;
  cursor: default !important;
}

.outline_card_wrapper {
  box-shadow: 2px 7px 12px rgba(172, 152, 99, 0.17);
  height: 50px;
  display: flex;
  flex-basis: 152px;
  flex-shrink: 0;
  align-items: center;
  border-radius: 8px;
  padding-left: 9px;
  padding-right: 5px;
  color: var(--input-border-color);
}

.warning_card {
  border: 1px solid #eba900;
  background-color: #fdf6e5;
}

.success_card {
  background-color: #e0fff0;
  border: 1px solid #2eaf71;
}

.action_required_card {
  background-color: #fcece7;
  border: 2px solid #e14514;
}

.danger_card {
  border: 1px solid #e14514;
  background-color: #fcece7;
}

.search_wrapper {
  flex-basis: 250px;
  margin-top: -5.5px;
}
.pending_queue_table_header {
  padding-inline: 2rem;
  position: relative;
}

.pendingActionTable {
  overflow-x: auto;
  scroll-behavior: smooth;
}
.pendingActionTable::-webkit-scrollbar {
  height: 10px;
}

.pendingActionTable::-webkit-scrollbar {
  display: initial;
}

.pendingActionTable::-webkit-scrollbar-thumb {
  background-color: rgb(173, 173, 173);
  border-radius: 30px;
}

.pendingActionTable::-webkit-scrollbar-button {
  display: initial;
}

.pendingActionTable::-webkit-scrollbar {
  -ms-overflow-style: initial;
  scrollbar-width: thin;
}

.pendingActionTable::-webkit-scrollbar-corner {
  background-color: transparent;
}

@media (max-width: 968px) {
  .grid_3 {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .grid_2 {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .pending_queue_table_header {
    position: relative;
    margin-bottom: 3rem;
    padding-inline: 1rem;
  }
  .pendingBoxContainer {
    position: absolute !important;
    top: 62px;
    left: 5px;
  }
}

@media screen and (max-width: 768px) {
  .pend-actions-cont {
    margin-top: 0 !important;
  }
  .pending_card_wrapper {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin: 10px;
  }
  .pending_queue_title {
    padding-left: 2rem;
  }
  .table_heading {
    font-size: 15px !important;
  }
  .pending_queue_table_header {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10rem;
  }
  .search_wrapper {
    flex: 1;
    width: 100%;
  }

  .pending_card {
    padding: 1px;
  }
  .pending_stats {
    padding-right: 18px;
  }
  .pendingBoxContainer {
    position: absolute !important;
    top: 190px;
    left: 50%;
    width: 91%;
    flex-direction: column;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 450px) {
  .pending_queue_table_header {
    padding-inline: 1rem;
  }
}
