.performance-cards {
  background-color: #fff;
  background-image: url('../../../resources/assets/performars-background.svg');
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  margin-top: 1.5rem;
}

.right-button {
  padding-top: 1rem;
  padding-inline: 1.5rem;
}

.share-button {
  padding: 8px 24px;
  outline: none;
  border: none;
  border-radius: 1.5rem;
  background: var(--orange-color);
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.share-button:hover {
  transform: scale(1.05);
  background: #ec9d76;
}

.share-button p {
  font-size: 14px;
}

.share-svg {
  font-size: 14px;
  height: 16px;
  width: 16px;
}

.banner-heading {
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.5;
}

.banner-heading p {
  color: var(--input-border-color);
}

.cards {
  padding: 5rem;
  column-gap: 4rem;
  row-gap: 60px;
}

.cards object {
  width: 70px;
}

.card-one,
.card-two,
.card-three {
  border: 2px solid #eaeaea;
  border-radius: 10px;
  padding: 0 0.8rem;
  background-color: #fff;
  width: 100%;
  max-width: 376px;
  margin: auto;
  overflow: hidden;
}

.card-three {
  border: 2px solid #eaeaea;
}

.upper-section {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  position: relative;
  z-index: 99;
}

.dashed-border {
  border: 1px dashed #eaeaea;
  position: relative;
  z-index: 99;
}

.card-title {
  justify-content: center;
}

.card-title span {
  margin-left: 7px;
  cursor: pointer;
}

.card-title h2 {
  font-weight: 600;
  color: #3b3b3b;
  font-size: 1.2rem;
}

.card-title p {
  font-size: 0.8rem;
  color: #3b3b3b;
}

.below-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-items: center;
  padding: 0.8rem;
  position: relative;
  z-index: 99;
}

.below-des p:first-of-type {
  color: var(--input-border-color);
  font-weight: 500;
}

.below-des p:nth-of-type(2) {
  color: var(--grey-text-color);
  font-size: 12px;
}

.card-two {
  border: none;
  transform: scale(1.3);
  position: relative;
}

/* ----TEXT-TRUNCATE---- */
.card-title,
.below-des {
  overflow: hidden;
}

.card-title h2,
.below-section p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ----TEXT-TRUNCATE---- */
.card-title,
.below-des {
  overflow: hidden;
}

.card-title h2 {
  font-size: 14px;
}

.card-title h2,
.below-section p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--input-border-color);
}

/* ------------------- */

.card-two::before {
  content: '';
  background-image: conic-gradient(#f99602 20deg, transparent 120deg);
  height: 200%;
  width: 200%;
  position: absolute;
  left: -50%;
  top: -50%;
  animation: rotate 2s linear infinite;
}

.card-two::after {
  content: '';
  width: 98%;
  height: 97%;
  border-radius: 10px;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  top: 1.5%;
  left: 1%;
  display: grid;
  place-items: center;
  font-size: 20px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.card-three {
  border: 2px solid #f9e09f;
}

@media screen and (max-width: 1400px) and (min-width: 768px) {
  .performance-cards .cards {
    padding: 5rem 1.5rem;
  }


  .performance-cards .upper-section,
  .performance-cards .below-section {
    padding-left: 0;
    padding-right: 0;
  }

  .performance-cards .upper-section {
    gap: 0.75rem;
    justify-content: center;
  }

  .performance-cards .below-des p {
    font-size: 0.75rem;
  }

  .performance-cards .card-one object,
  .performance-cards .card-three object {
    width: 40px;
  }

  .performance-cards .card-one h2,
  .performance-cards .card-three h2 {
    font-size: 0.875rem;
  }
}

.collapse-btn {
  display: none;
}

@media (max-width: 968px) {
  .sm-hide {
    display: none !important;
  }

  .collapse-btn {
    display: block;
    width: 28px;
    height: 28px;
    background-color: #f99602;
    display: grid;
    place-items: center;
    color: #fff;
    border-radius: 50%;
  }

  .performance-cards .cards {
    column-gap: 3rem;
  }
}

.absolute-img {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 767px) {
  .performance-cards {
    margin-top: 10px !important;
  }

  .right-button {
    margin-bottom: 10px;
  }

  .banner-heading p {
    font-size: 12px;
  }

  .performance-cards .cards {
    display: grid;
    grid-template-columns: 100%;
    padding: 2rem 1rem;
    column-gap: 0;
    row-gap: 1.5rem;
    place-items: center;
  }

  .performance-cards .card-one,
  .performance-cards .card-three {
    width: 100%;
  }

  .performance-cards .card-two {
    transform: scale(1);
    grid-row-start: 1;
  }

  .card-two::before {
    height: 230%;
  }

  .card-two::after {
    height: 97%;
    top: 1.5%;
  }

  .share-button {
    left: 10px;
  }

  .share-button p {
    font-size: 12px;
  }
}