.loginButton,
.cancel {
  flex-shrink: 0;
  background-color: var(--primary-color);
  border: none;
  border-radius: 8px;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  line-height: 11.962px;
  cursor: pointer;
  height: 40px;
  text-transform: capitalize;
  width: 150px;
  font-size: 12px;
  transition:
    transform 0.3s ease,
    background-color 0.3s ease !important;
}

.loginButton:hover {
  background-color: #4062ca !important;
  transform: scale(1.05);
}

.cancel {
  border: 1px solid var(--primary-color);
  color: var(--input-border-color);
  background-color: transparent !important;
  transition: transform 0.3s ease;
}

.cancel:hover {
  transform: scale(1.05);
  background-color: #ddebff !important;
  color: var(--primary-color);
}

.cancel:disabled {
  color: #a9a9a9;
  cursor: not-allowed;
}

.add_view_button {
  display: flex;
  background-color: #0493ce;
  padding: 10px;
  border-radius: 10px;
  gap: 5px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.add_view_button:hover {
  transform: scale(1.07);
  background-color: #002970;
}

.add_view_button button {
  background-color: transparent !important;
  border: none;
  color: white;
  cursor: pointer;
}

.iconsSection2 button {
  background: var(--primary-color);
  color: white;
  border: none !important;
  transition:
    transform 0.3s ease,
    background-color 0.3s ease,
    border-color 0.3s ease;
}

.iconsSection2 button svg {
  width: 14px;
  height: 12px !important;
}

.iconsSection2 button:hover {
  transform: scale(1.05);
  background-color: #4062ca;
  border-color: rgb(255, 255, 255);
}

@media screen and (max-width: 767px) {
  .iconSection2-mobile button {
    position: absolute;
    top: 58%;
    right: 40px;
    height: 36px;
    width: 36px;
  }
  .iconSection2-mobile .mobileTitle {
    display: none;
  }
  .loginButton,
  .cancel {
    width: 100px;
  }
  .fildelb-btn {
    width: 100%;
    text-align: center;
    margin-top: 0;
  }
}
