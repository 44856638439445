.top_dashboard {
  background-color: #f3f4f6;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}

.top_box {
  display: grid;
  background-color: white;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.2rem;
}

.bottom_box {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  min-height: 200px;
  margin-top: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.2rem;
}

.top_box_heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.top_box_drop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.top_box_heading p {
  font-size: 16px;
  font-weight: 500;
  color: var(--input-border-color);
}

.top_box_boxes {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 14px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.top_box_box {
  background-color: #f6f6f8;
  border: 1px solid #a5aab226;
  border-radius: 12px;
  padding: 5px;
  width: 20%;
}

.top_box_top {
  display: flex;
  flex-direction: column;
}

.top_box_head {
  background-color: #e3edff;
  border: 1px solid #a5aab226;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_box_divs {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px 2px 15px 2px;
  gap: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.top_box_head_left {
  padding-right: 10px;
  border-right: 1px solid #c6c6c6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.top_box_head_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.top_box_head_left h1 {
  font-weight: 600;
  font-size: 16px;
  color: var(--input-border-color);
}

.top_box_head_left p {
  font-weight: 500;
  font-size: 12px;
  color: #767676;
}

.top_box_head_right h1 {
  font-weight: 600;
  font-size: 16px;
  color: var(--input-border-color);
}

.top_box_head_right p {
  font-weight: 500;
  font-size: 12px;
  color: #767676;
}

.top_box_bottom {
  margin-top: 5px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #a5aab226;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.top_box_bottom p {
  font-size: 12px;
  font-weight: 500;
}

.top_box_bottom h3 {
  font-weight: 600;
  font-size: 12px;
}

.bottom_box_chart1 {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.03) 2px 4px 8px -1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  min-height: 400px;
}

.bottom_box_chart1_sec {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.bottom_box_chart2 {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.03) 2px 4px 8px -1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
}

.bottom_box_chart2_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
}

.bottom_box_chart2_head h1 {
  font-size: 16px;
  font-weight: 500;
}

.bottom_box_chart2_head_buttons {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
}

.bottom_box_button {
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #377cf6;
  padding: 8px 12px 8px 12px;
  color: #377cf6;
  cursor: pointer;
  height: 36px;
  transition: all 0.3s ease;
}

.bottom_box_button:hover {
  background-color: #ddebff;
}

.bottom_box_button.active {
  background-color: #377cf6;
  color: #fff;
}

.bottom_box_button.active:hover {
  background-color: #377cf6;
}

.bottom_graphchange {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 0.5px solid #cee0ff;
  background-color: #ecf3ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bottom_graphchange:hover {
  transform: scale(1.08);
}

.bottom_graphchange_div {
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
  margin-top: -10px;
}

.bottom_box_chart1 p {
  font-weight: 500;
  font-size: 16px;
  margin-top: 13px;
  margin-left: 18px;
}

.bottom_box_chart2_head_buttons_sec {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 4px solid #efefef;
}

.bottom_box_button_sec {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 13px 5px 13px;
  color: #b4b4b4;
  background-color: #efefef;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bottom_box_button_sec:hover {
  background-color: #ddebff;
}

.bottom_box_button_sec.active {
  background-color: #377cf6;
  color: #fff;
  border-radius: 6px;
}

.bottom_box_button_sec.active:hover {
  background-color: #377cf6;
}

.editModal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.editModal img {
  transition: all 0.3s ease;
  height: 36px;
  width: 36px;
  border-radius: 8px;
}

.editModal img:hover {
  transform: scale(1.06);
}

.bottom_box_chart1_sec {
  width: 100%;
  height: 463px;
  padding: 1rem;
  /* border-right: 1px dotted #d7d9dc; */
}

.bottom_box_chart_rad {
  width: 100%;
  height: 463px;
  padding: 1rem;
  border-right: 1px dotted #d7d9dc;
}

.bottom_box_chart_rad1 {
  width: 100%;
  height: 463px;
  padding: 1rem;
}

.ed_perc {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.sel_opt {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .bottom_box_chart_rad {
    width: 100%;
    height: 330px;
    padding: 1rem;
    border-right: none;
    border-bottom: 1px dotted #d7d9dc;
  }

  /* .top_box_heading p {
    margin-top: 30px;
  } */

  .ed_perc {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
  }

  .bottom_box_chart_rad {
    width: 100%;
    height: 330px;
    padding: 1rem;
    border-right: none;
    border-bottom: 1px dotted #D7D9DC;
  }

  .bottom_box_chart_rad1 {
    width: 100%;
    height: 330px;
    padding: 0;
  }

  .top_box_heading {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .top_box_boxes {
    flex-direction: column;
  }

  .top_box_box {
    width: 100%;
  }

  .top_box_drop {
    flex-direction: column;
  }

  .bottom_box_chart1_sec {
    display: flex;
    flex-direction: column;
    height: auto;
    border-right: none;
  }

  .bottom_box_button_sec {
    padding: 3px 5px 3px 5px;
  }

  .bottom_graphchange_div {
    margin-top: -7px;
  }

  .bottom_box_chart_rad1 {
    width: 100%;
    height: 330px;
    padding: 0;
  }

  .top_box_heading {
    gap: 12px;
  }

  .top_box_boxes {
    flex-direction: column;
  }

  .top_box_box {
    width: 100%;
  }

  .bottom_box_chart1_sec {
    display: flex;
    flex-direction: column;
    height: auto;
    border-right: none;
  }

  .bottom_box_button_sec {
    padding: 3px 5px 3px 5px;
  }

  .bottom_graphchange_div {
    margin-top: -7px;
  }

  .top_dashboard {
    margin: 3px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1025px) {
  .top_box_drop {
    flex-direction: column;
  }

  .ed_perc {
    gap: 24px;
  }

  .top_box_heading p {
    font-size: 16px;
    font-weight: 500;
    color: var(--input-border-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom_box_chart1_sec {
    display: flex;
    flex-direction: column;
    height: auto;
    border-right: none;
  }

  .bottom_box_button_sec {
    padding: 3px 7px 3px 7px;
  }

  .top_box_boxes {
    flex-wrap: wrap;
    justify-content: center;
  }

  .top_box_boxes>div {
    flex-basis: calc(76.33% - 6.67px);
    margin-bottom: 10px;
  }

  .top_box_boxes>div:nth-child(3n + 1) {
    margin-left: 0;
  }

  .top_box_boxes>div:nth-child(3n) {
    margin-right: 0;
  }

  .top_box_boxes>div:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }
}