.openRefresh{
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.72);
  justify-content: center;
  align-items: center;
  z-index: 400;
}
.openRefresh_page_icon{
    padding:0.6rem;
    border: 1px solid #377CF6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F5FD;
    
}
.openRefresh_page{
    width: 20%;
    background-color: #FFFFFF;
    padding: 3rem 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;
}
.openRefresh_page_text{
    font-family: 'Poppins',sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    width: 80%;
}
.openRefresh_page_btns{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.openRefresh_page_btn_yes,.openRefresh_page_btn_No{
    padding: 0.4rem 2rem;
    background-color: #F5F5FD;
    border: 1px solid #F5F5FD;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: 'Poppins',sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.openRefresh_page_btn_yes:hover{
    color: #377CF6;
    border: 1px solid #377CF6;
    transform: scale(1.05);}
.openRefresh_page_btn_No:hover{
    color: #377CF6;
    border: 1px solid #377CF6;
    transform: scale(1.05);
}
