.filter-modal {
  position: fixed;
  inset: 0;
  transition: all 500ms ease-in-out;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

.filter-modal .transparent-model {
  position: relative !important;
  top: auto !important;
  left: auto !important;
  background-color: transparent !important;
}
.modal-close {
  transform: translateY(-200%) !important;
  transition: all 300ms ease-in-out;
}
.modal-open {
  transform: translateY(0);
  transition: all 300ms ease-in-out;
}

.fil__datepicker_wrapper {
  position: relative;
  line-height: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 11;
}

.fil__datepicker_content {
  position: absolute;
  left: 36px;
  z-index: 100;
  background-color: var(--white-color);
  border-radius: 4px;
  border: 1px solid var(--border-color);
  top: 20px;
}

.fil__datepicker_btns {
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  margin-bottom: 1rem;
}

.fil__datepicker_btns button {
  position: static;
}

.fil__datepicker_btns button:first-child {
  margin-right: 0.5rem;
}
