.banner-main {
  padding: 1.5rem 0;
  width: 100%;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  z-index: 1;
}

.leaderboard-input {
  padding: 0 8px;
}

.leaderboard-input::placeholder {
  color: var(--grey-border-color);
}

.upload-notice {
  color: #ff4d4d;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  display: block;
}

.bg-blue {
  background: linear-gradient(0deg, #215f9a 0%, #44adf9 100%);
}

.bg-green-radiant {
  background: linear-gradient(0deg, #89c115 100%, #8ec126 100%);
}

.banner-wrap {
  display: flex;
  align-items: center;
}

.banner-heading {
  margin-top: rgba(255, 255, 255, 0.584);
}

.dealer-dropdown-filter .dealer-toggler {
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.551);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  color: var(--input-border-color);
  font-size: 12px;
  transition: all 0.3s ease;
}

.dealer-dropdown-filter .dealer-toggler-load {
  opacity: 0.6;
}

.dealer-dropdown-filter .dealer-toggler-load:hover {
  cursor: not-allowed;
  transform: scale(1) !important;
}

.dealer-dropdown-filter {
  position: absolute;
  bottom: 0.8rem;
  right: 115px;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.solar-heading {
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

.green-banner-heading {
  line-height: 47px;
  font-weight: 600;
  font-size: 3rem;
}

.banner-left {
  gap: 1.5rem;
  width: 50vw;
  z-index: 110;
}

.left-ban-des {
  font-size: 0.8rem;
  line-height: 16px;
  margin-left: 5px;
  color: #fff;
  font-weight: 300;
}

.left-ban-des span {
  font-weight: 500;
  text-transform: uppercase;
}

.straight-line {
  width: 1.5px;
  height: 130px;
  background-color: #d9edfa;
  opacity: 0.3;
  margin-right: 1.5rem;
  z-index: 99;
}

.banner-right {
  width: 40vw;
  z-index: 99;
}

.banner-names {
  line-height: 18px;
  gap: 0.7rem;
  z-index: 99;
}

.owner-heading {
  color: #dedede;
  font-size: 0.7rem;
}

.owner-names {
  color: #fff;
  font-size: 0.9rem;
}

.banner-trophy {
  align-self: flex-end;
  position: absolute;
  right: 0;
  top: -35px;
}

.user-trophy {
  align-self: flex-end;
  position: absolute;
  right: 0;
  top: -30px;
}

.banner-star-1 {
  position: absolute;
  right: 205px;
  top: 45px;
  animation: spin 2.5s ease-in-out infinite;
}

.banner-star-2 {
  position: absolute;
  right: 90px;
  top: 3px;
  animation: spin 2.5s ease-in-out infinite;
}

.banner-star-3 {
  position: absolute;
  right: 165px;
  top: 142px;
  animation: spin 2.5s ease-in-out infinite;
}

.banner-trophy object {
  width: 220px;
}

.edit-button {
  height: 33px;
  position: absolute;
  right: 0.8rem;
  bottom: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  padding: 0.5rem 1.5rem;
  outline: none;
  border: none;
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0.551);
  backdrop-filter: blur(10px);
  color: var(--input-border-color);
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.edit-button:hover {
  transition: all 300ms ease-in-out;
  background-color: #ddebff;
  transform: scale(1.05);
}

.edit-button-load {
  opacity: 0.6;
}

.edit-button-load:hover {
  transform: scale(1);
  cursor: not-allowed;
}

.dealer-dropdown-filter .dealer-toggler:hover {
  transition: all 300ms ease-in-out;
  background-color: #ddebff;
  transform: scale(1.05);
}

.dealer-dropdown-filter .dealer-toggler.open .fa-chevron-down {
  transform: rotate(180deg);
}

.fa-chevron-down {
  transition: transform 300ms ease-in-out;
}

.edit-svg {
  font-size: 1rem;
  height: 16px;
  width: 16px;
}

.admin-edit-button {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.admin-edit-button:hover {
  transform: scale(1.08);
}

.admin-edit-img {
  background: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.radiant-anime {
  position: absolute;
  background-image: radial-gradient(
    farthest-corner at 40px 40px,
    #f35 10%,
    transparent 50%,
    #43e 100%
  );
  opacity: 0.7;
  filter: blur(40px);
  height: 100vh;
  width: 100%;
  overflow: hidden;
  animation: color 4s linear infinite;
}

@keyframes color {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.radiant-anime-2 {
  position: absolute;
  background-image: radial-gradient(
    farthest-corner at 40px 40px,
    #fdd68a 5%,
    transparent 50%,
    #11ff1b 100%,
    #ffc805 100%,
    #cefff0 100%
  );
  opacity: 0.7;
  filter: blur(40px);
  height: 100vh;
  width: 100%;
  overflow: hidden;
  animation: color2 4s linear infinite;
}

@keyframes color2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: scale(0.5) rotate(0deg);
  }

  20% {
    transform: scale(1) rotate(360deg);
  }

  40% {
    transform: scale(0.7) rotate(360deg);
  }

  60% {
    transform: scale(1) rotate(0deg);
  }

  70% {
    transform: scale(1) rotate(0deg);
  }

  80%,
  100% {
    transform: scale(0) rotate(0deg);
  }
}

@media screen and (max-width: 1200px) {
  .banner-trophy img {
    width: 200px;
  }

  .banner-trophy {
    top: -18px;
  }

  .banner-star-1 {
    top: 50px;
    width: 18px;
    right: 205px;
  }

  .banner-star-2 {
    width: 18px;
    top: 2px;
    right: 100px;
  }

  .banner-star-3 {
    right: 140px;
    top: 130px;
    width: 14px;
  }

  .green-banner-heading {
    font-size: 2rem;
    line-height: 37px;
  }
}

.banner-left {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .straight-line {
    display: none;
  }
  .banner-wrap {
    flex-direction: column;
    gap: 1rem;
  }

  .banner-left {
    padding-right: 40px;
  }

  .banner-left,
  .banner-right {
    width: 100vw;
    padding-left: 10% !important;
  }

  .banner-right {
    margin-right: auto;
  }

  .banner-trophy,
  .user-trophy,
  .banner-star-1,
  .banner-star-2,
  .banner-star-3 {
    display: none;
  }

  .green-banner-heading {
    font-size: 2rem;
    line-height: 37px;
  }

  .banner-heading {
    margin-top: 0;
  }
}

@media (max-width: 968px) {
  .dealer-dropdown {
    left: auto !important;
    right: 0 !important;
  }

  .owner-names {
    font-size: 0.75rem;
  }

  .banner-names {
    flex-direction: row !important;
  }

  .banner-right {
    width: 75%;
  }

  .banner-wrap {
    flex-direction: column;
    gap: 1rem;
  }

  .straight-line {
    display: none;
  }

  .solar-heading {
    font-size: 1.5rem;
    line-height: 30px;
  }
}

@media (max-width: 400px) {
  .banner-left,
  .banner-right {
    padding-left: 10px !important;
  }

  .banner-left img {
    max-width: 100px !important;
  }

  .absolute-img {
    max-width: 230px;
  }

  .green-banner-heading,
  .solar-heading {
    font-size: 1.5rem;
    line-height: 30px;
  }
}
