.filter_btn {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #377cf6;
  cursor: pointer;
}

.dropdown_container {
  position: absolute;
  width: 109px;
  background-color: #fff;
  padding-inline: 7px;
  height: 86px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  justify-content: center;
  z-index: 10;
  border: 1.4px solid #377cf6;
  border-radius: 11px;
  top: 30px;
  right: 0;
}

.check_label {
  font-size: 12px;
  color: #424242;
}

.input_check {
  display: none;
}

.check_label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 15px;
  font-size: 11px;
}

.check_label:hover {
  color: #377cf6;
  font-size: 13px;
}

.check_label:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border: 1px solid #377cf6;
  position: absolute;
  left: 0;
  bottom: 1px;
  border-radius: 4px;
  background-color: #fff;
}

.checkbox .check_label:before {
  border-radius: 3px;
}

.input_check:checked + .check_label:before {
  content: '';
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 12px;
  background-image: url(../../../../../../resources/assets/check_svg.svg);
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
}
