.dropdown > .css-1nmdiq5-menu {
  scrollbar-color: rgb(173, 173, 173) #fff;
  scrollbar-width: thin;
}

.dropdown > .css-1nmdiq5-menu::-webkit-scrollbar {
  width: 8px;
}

.dropdown > .css-1nmdiq5-menu::-webkit-scrollbar-thumb {
  background-color: rgb(173, 173, 173);
  border-radius: 50%;
  -ms-overflow-style: initial;
}

.dropdown > .css-1nmdiq5-menu::-webkit-scrollbar-track {
  background-color: transparent;
}
