
.chartContainer {
    width: 100%;
    height: 100%;
    padding: 1rem;
    font-family: Arial, sans-serif;
  }
  
  .barChart {
    min-height: 400px;
  }
  
  .grid {
    stroke: #e0e0e0;
    stroke-width: 1px;
  }
  
  .axis {
    font-size: 12px;
    font-weight: 400;
    color: #767676;
  }
  
  .axis line {
    stroke: #e0e0e0;
  }
  
  .axis path {
    stroke: #e0e0e0;
  }
  
  .bar {
    transition: opacity 0.3s ease;
  }
  
  .barLabel {
    font-size: 10px;
    font-weight: 500;
    fill: #666666;
  }
  
  .legend {
    font-size: 12px;
    cursor: pointer;
  }
  
  .legend:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  
  /* Tooltip styles */
  .tooltip {
    font-size: 12px;
  }
  
  .tooltip :global(.recharts-tooltip-item) {
    padding: 4px 0;
    font-size: 12px;
  }
  
  .tooltip :global(.recharts-tooltip-item-name) {
    color: #666666;
  }
  
  .tooltip :global(.recharts-tooltip-item-separator) {
    margin: 0 4px;
  }
  
  .chartWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.03) 2px 4px 8px -1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding-bottom: 10px;
  }
  .recharts-surface{
      padding-top: 20px;
  }
  
  .legend{
      font-size: 10px;
  }
  
  .chartContainer {
    width: 100%;
    background-color: white;
    /* padding-left: 13px;
    padding-right: 13px;
    padding-top: 10px;
    padding-bottom: 10px; */
    padding: 10px 13px;
    border-radius: 18px;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .selectWrapper {
    display: flex;
    flex-direction: row;
    /* gap: 15px; */
    justify-content: space-around;
    margin-top: 20px;
  
  }

  @media screen and (max-width: 767px){
    .axis{
      font-size: 8px;
      font-weight: 500;
    }
  }

  .recharts-radial-bar-label {
    fill: #000000 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #000000 !important;
}

.radial-bar-label {
  position: absolute; /* 'insideBottom' isn't a standard CSS position, using absolute instead */
  color: #000;
  stroke: #000;
  fill: #000;
  font-size: 9px;
  font-weight: 200;
  text-anchor: middle; /* Centers the text horizontally */
  dominant-baseline: central; /* Centers the text vertically */
}

  