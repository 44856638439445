.filtericonHistory {
  display: flex;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  border: 1.2px solid #377cf6;
  cursor: pointer;
  transition: all 0.5s ease;
}

.filtericonHistory:hover {
  background-color: #ddebff;
  transform: scale(1.05);
}

.ICONSTYLETHREEDOT {
  width: auto;
  height: auto;
  margin-left: 15px;
  transform: rotate(90deg);
  align-items: center;
  justify-content: center;
}

/* HERE FOR RESPONSIVESNESS */

@media screen and (max-width: 1200px) {
  .filtericonHistory {
    margin-top: -2px !important;
  }
}

@media screen and (max-width: 768px) {
  .filtericonHistory {
    display: flex;
    height: 30px;
    width: 30px;
    align-items: center;
    margin-top: 0.3px !important;
    margin-left: 23px;
    border-radius: 8px;
    border: 1.2px solid #377cf6;
    cursor: pointer;

    /* .buttonGroup INSIDE THIS GROUP WIDTH IS ALSO REMOVE */
  }

  .ICONSTYLETHREEDOT {
    width: auto;
    height: auto;
    margin-left: 12px;
    transform: rotate(90deg);
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 460px) and (max-width: 768px) {
  .filtericonHistory {
    display: flex;
    align-items: center;
    height: 36px;
    width: 36px;
    margin-top: 1px !important;
    margin-left: 69px !important;
    border-radius: 8px;
    border: 1.2px solid #377cf6 !important;
    cursor: pointer;
  }

  .ICONSTYLETHREEDOT {
    width: auto;
    height: auto;
    margin-left: 14px;
    align-items: center;
    justify-content: center;
  }
}

.prdropdownX ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.prdropdownX li {
  cursor: pointer;
  font-size: 12px;
  padding: 5px 0;
  transition:
    color 0.3s ease,
    transform 0.3s ease;
}

.prdropdownX li:hover {

  transform: scale(1.05);
}

@media (max-width:968px) {
  .mobile_tooltip {
    display: none !important;
  }
}

@media only screen and (min-width: 340px) and (max-width: 539px) {
  .filtericonHistory {
    display: flex;
    align-items: center;
    height: 30px;
    width: 30px;
    margin-top: 0.3px !important;
    margin-left: 23px;
    border-radius: 8px;
    border: 1.2px solid #377cf6;
    cursor: pointer;
  }

}