.thumbnail_wrapper {
  background-color: #f2f2f2;
  position: relative;
  width: 100%;
  height: 108px;
  display: grid;
  border-radius: 8px;
  place-items: center;
  margin-left: 6px;
}

.checkbox_wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.thumbnail_wrapper:hover .checkbox_wrapper {
  opacity: 1;
}

.checkbox_wrapper:not(.selected) {
  opacity: 0;
}

.avatar_name_div {
  margin-left: 6px;
}

.avatar_name_conatiner {
  position: relative;
  cursor: pointer;
}

.card_title {
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--input-border-color);
  display: block;
  max-width: 100px;
}

.card_title_hide {
  display: none;
  position: absolute;
  top: 95%;
  left: 40%;
  transform: translateX(-50%);
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 4px;
  z-index: 10;
  opacity: 1;
}

.avatar_name_conatiner:hover .card_title_hide {
  display: inline-block;
}

.avatar_circle {
  background-color: #afafaf;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  display: grid;
  place-items: center;
  top: 10px;
  left: 10px;
  font-size: 12px;
  color: #fff;
  visibility: hidden;
}

.card_btn {
  cursor: pointer;
  margin-left: 7px;
  transition: all 0.3s ease;
}

.card_btn:hover {
  color: #377cf6;
  transform: scale(1.09);
}

.card_content {
  color: var(--input-border-color);
  font-size: 10px;
}
