.main_container {
  width: 100%;
  min-height: 100vh;
  background-color: var(--white-color);
  border-radius: 8px;
  cursor: default;
}

.hide {
  display: none;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 23px 25px;
}

.title {
  font-family: 'poppins' sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: var(--input-border-color);
  align-content: center;
}

.header_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.date_container {
  margin-bottom: 10px;
  cursor: pointer;
}

.appointment_container {
  font-family: 'inter' sans-serif;
  height: 90vh;
  overflow-y: scroll;
}

.appointments_list {
  background-color: #ebf3ff;
  padding: 5px;
  margin: 0px 8px;
}

.second_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.date_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ebf3ff;
  margin-bottom: 0px;
  padding: 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  border-radius: 0px;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.date_container:hover .appointment_quantity,
.date_container:hover .date_button {
  transform: scale(1.07);
  transition: transform 0.3s ease-in-out;
}

.name_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.name {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.approved {
  font-size: 12px;
  font-weight: 500;
  color: #21bc27;
  margin-bottom: 5px;
}

.name_container span {
  font-size: 12px;
  height: 28px;
  width: 28px;
  font-weight: 600;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  color: var(--project-icon-color-4);
}

.chevron_button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.date_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.name_container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.mail_and_number {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.no_appointment {
  font-size: 14px;
  font-weight: 500;
}

.dateAndTime {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.scheduled_date {
  font-family: 'inter' sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #8a8a8a;
}

.button_container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-bottom: 10px;
}

.appointment_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--white-color);
  margin-bottom: 8px;
  padding: 10px 25px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
}

.left_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 13px;
}

.mail_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
}

.phone_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
}

.back_button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.back_button:hover .icon {
  transform: scale(1.07);
}

.icon {
  height: 20px;
  width: 27px;
  font-weight: 600;
  transition: transform 0.3s ease;
}

.icon:hover {
  transform: scale(1.05);
}

.header_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.button_cancel {
  border-radius: 6px;
  padding: 8px 12px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  background-color: var(--delete-btn-color);
  color: var(--white-color);
  transition: transform 0.3s ease;
}

.button_cancel:hover {
  background-color: var(--delete-btn-hover);
  transform: scale(1.05);
}

.button_edit {
  border-radius: 6px;
  padding: 8px 12px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  background-color: var(--white-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  transition: transform 0.3s ease;
}

.button_edit:hover {
  background-color: #dde9ff;
  transform: scale(1.05);
}

@media (max-width: 1210px) {
  .phone_container {
    font-size: 12px;
    margin-right: 6px;
  }

  .button_cancel {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mail_container {
    font-size: 12px;
  }

  .dateAndTime {
    font-size: 13px;
  }

  .name {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .appointment_item {
    padding: 10px;
    gap: 0.5rem;
  }
  .header {
    padding: 1rem;
  }
}

@media (max-width: 600px) {
  .mail_and_number {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  .date {
    width: max-content;
  }

  .time {
    width: max-content;
  }
}

@media (max-width: 469px) {
  .appointment_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--white-color);
    margin-bottom: 8px;
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 6px;
    align-items: flex-start;
    gap: 22px;
  }

  .hide {
    display: none;
  }

  .mobile_view {
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-top: 0.2px solid #e0e0e0;
    width: 100%;
  }

  .viewMobile {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
  }

  .dateAndTime {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 5px;
  }

  .scheduled_date {
    padding: 12px 6px;
  }

  .name {
    font-size: 14px;
  }
}
