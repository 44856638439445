.absolute_zoom_control_wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
}
.bg_black {
  background: #000;
}

.close_btn {
  border: none;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  cursor: pointer;
}

.close_btn:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.zoom_control_wrapper {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
}

.zoom_in_btn {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding-block: 2px;
  line-height: 1;
  padding-inline: 8px;
  margin-top: 8px;
  transition: all 500ms;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
  transition: all 500ms;
}
.transparent {
  background: transparent;
}

.zoom_in_btn:hover {
  transition: all 500ms;
  transform: scale(1.1);
}
