.form_wrapper {
  background-color: #f6f6f6;
  padding-inline: 2rem;
  height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_conatiner::-webkit-scrollbar {
  display: block !important;
}

.header_title {
  padding-left: 13px;
}

.back_btn {
  transition: all 500ms;
  width: 28px;
  height: 28px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  font-size: 16px;
}

.back_btn:hover {
  transition: all 500ms;
  transform: scale(1.1);
}

.form_conatiner {
  max-width: 1089px;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  overflow: scroll;
  margin-inline: auto;
  height: 100vh !important;
}

.form_header {
  background: linear-gradient(90deg, #159cbf 0%, #6ec135 100%);
  height: 78px;
}

.form_header h3 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.form_header p {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
}

.form_content {
  max-width: 555px;
  margin-inline: auto;
}

.date_time_wrapper {
  border-left: 1px solid #f4f4f4;
  flex: auto;
}

.submit_btn {
  background-color: #377cf6;
  max-width: 472px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 18px;
  padding-block: 10px;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
}

.date_time_container {
  border-left: 1px solid #f4f4f4;
  padding-bottom: 24px;
  padding-inline: 32px;
}

.sm_padding {
  padding-bottom: 24px;
}

.sm_date_close_header {
  display: none;
}

.navigate_btn {
  margin-top: 16px;
  text-decoration: underline;
  color: #377cf6;
  transition: all 500ms;
}

.navigate_btn:hover {
  transition: all 500ms;

  transform: scale(1.1);
}

.slot_wrapper {
  flex-basis: 152px;
}

@media (max-width: 968px) {
  .form_wrapper.no_inner_padding {
    padding-inline: 0;
  }

  .date_time_container {
    border-left: none;
  }

  .sm_padding {
    padding-inline: 2rem;
  }

  .form_wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-inline: 0;
  }

  .date_time_container {
    width: 100%;
    background: #fff;
    margin-top: 32px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    margin-inline: 20px;
  }

  .btn_wrapper {
    padding-bottom: 16px;
    padding-inline: 20px;
  }

  .sm_padding {
    padding-inline: 20px;
  }

  .sm_date_close_header {
    display: flex;
  }

  .back_btn {
    position: absolute;
    left: 0;
  }

  .form_wrapper {
    min-height: auto;
    height: auto !important;
  }

  .date_time_container {
    border: none;
  }

  .form_conatiner {
    border-radius: 0;
  }

  .form_conatiner.bg_transparent {
    background-color: transparent;
  }

  .form_content.mobile_hidden {
    display: none;
  }

  .form_header h3 {
    text-align: center;
  }

  .date_header_label {
    display: none;
  }

  .form_content {
    padding-inline: 24px;
  }

  .header_title {
    padding-left: 0;
  }

  .form_header {
    height: auto;
    padding-top: 21px;
    padding-bottom: 21px;
  }
}

@media (max-width: 450px) {
  .back_btn {
    margin-left: 0 !important;
    left: 20px;
  }
  .date_time_container {
    margin-inline: 12px;
  }

  .calendar_h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}
