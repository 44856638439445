.commissionContainer {
  background-color: var(--white-color);
  justify-content: space-between;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid var(--border-color);
}

.commissionSection {
  display: flex;
  align-items: center;
  padding: 16px 18px 16px 16px;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}

.dashBoard-container {
  background-color: var(--white-color);
  justify-content: space-between;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid var(--border-color);
}

.iconContainer {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
}

.iconsSection2 {
  display: flex;
  border: none;
}

.iconsSection2 img {
  width: 10px !important;
  height: 10px !important;
}

.iconsSection2 button svg {
  width: 14px;
  height: 16px;
}

.iconsSection2 button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: var(--white-color);
  padding: 8px 12px;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 500;
  color: var(--btn-text-color);
  cursor: pointer;
}

.iconsSection2 button:disabled {
  background-color: var(--row-bg-color);
}

.iconsSection2 button:disabled button > svg {
  fill: #fefefe;
}

.iconsSection2 .hover-btn:hover {
  background-color: var(--active-text-color) !important;
  border: 1px solid var(--active-text-color) !important;
  color: var(--white-color) !important;
  transition: 0.4s all linear;
  opacity: 0.6;
}

.iconsSection2-conan {
  display: flex;
  border: none;
}

.iconsSection2-conan img {
  width: 18px;
  height: 18px;
}

.iconsSection2-conan button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--primary-color);
  padding: 8px 12px;
  border: 1px solid var(--button-border-color);
  font-size: 13px;
  border-radius: 8px;
  font-weight: 500;
  color: var(--btn-text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.iconsSection2-confex {
  display: flex;
  border: none;
}

.iconsSection2-confex button:hover {
  background-color: #4062ca;
  transform: scale(1.05);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.iconsSection2-confex img {
  width: 18px;
  height: 18px;
}

.iconsSection2-confex button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--primary-color);
  padding: 8px 12px;
  border: none;
  font-size: 13px;
  border-radius: 8px;
  font-weight: 500;
  color: var(--white-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.iconsSection2-confex button:disabled {
  background-color: var(--row-bg-color);
  transform: none;
  pointer-events: none;
}

.iconsSection2-confex button:disabled button > svg {
  fill: #fefefe;
}

.iconsSection2-confex .hover-btn:hover {
  background-color: var(--active-text-color) !important;
  border: 1px solid var(--active-text-color) !important;
  color: var(--white-color) !important;
  transition: 0.4s all linear;
  opacity: 0.6;
}

.iconsSection-filter button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: none;
  background-color: var(--primary-color);
  width: 36px;
  height: 36px;
  border-radius: 8px;
  font-weight: 500;
  color: var(--btn-text-color);
  cursor: pointer;
  transition:
    transform 0.3s ease,
    background-color 0.3s ease;
}

.iconsSection-filter button:hover {
  background-color: #305eae;
  transform: scale(1.05);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.commission-back {
  display: flex;
  align-items: center;
  gap: 10px;
}

.commission-back .back-btn {
  transition: all 0.3s ease;
}

.commission-back .back-btn:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.select-type-label {
  position: static !important;
  display: block;
  margin-bottom: -15px;
}

.UserManageTable {
  position: relative;
  max-height: 170vh;
  background: var(--white-color);
  overflow-y: auto;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.UserManageTable::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.UserManageTable::-webkit-scrollbar {
  display: initial;
}

.UserManageTable::-webkit-scrollbar-thumb {
  background-color: rgb(173, 173, 173);
  border-radius: 30px;
}

.UserManageTable::-webkit-scrollbar-button {
  display: initial;
}

.UserManageTable::-webkit-scrollbar {
  -ms-overflow-style: initial;
  scrollbar-width: thin;
}

.UserManageTable::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* table style  */
.TableContainer {
  position: relative;
  height: 60vh;
  background: var(--white-color);
  overflow-y: auto;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.TableContainer::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.TableContainer::-webkit-scrollbar {
  display: initial;
}

.TableContainer::-webkit-scrollbar-thumb {
  background-color: rgb(173, 173, 173);
  border-radius: 30px;
}

.TableContainer::-webkit-scrollbar-button {
  display: initial;
}

.TableContainer::-webkit-scrollbar {
  -ms-overflow-style: initial;
  scrollbar-width: thin;
}

.TableContainer::-webkit-scrollbar-corner {
  background-color: transparent;
}

.adder-input {
  border: 1px solid var(--button-border-color);
  width: 40px;
  height: 24px;
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.action-archive {
  position: relative;
  display: inline-block;
}

.action-archive .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: var(--active-text-color);
  color: var(--white-color);
  text-align: center;
  border-radius: 6px;
  position: absolute;
  left: -80px;

  top: 0.5rem;
}

.action-archive:hover .tooltiptext {
  visibility: visible;
}

table {
  width: 100%;
  background-color: var(--white-color);
  border-collapse: collapse;
  position: relative;
}

table tbody {
  width: 100%;
}

table thead tr {
  background-color: #d5e4ff;
  position: sticky;
  top: 0;
  z-index: 1;
}

table thead tr th {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding: 0.8rem 1rem 0.8rem 1rem;
}

.table-header {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
}

.table-header p {
  color: var(--input-border-color);
  font-size: 14px;
  font-weight: 600;
}

table tbody tr {
  border-bottom: 1px solid var(--border-color);
}

table tbody tr:hover {
  transition: 0.4s all linear;
  background-color: #66708520;
}

table tbody tr td {
  padding: 0.8rem 1rem 0.8rem 1rem;
  font-size: 12px;
  width: auto;
  color: var(--input-border-color) !important;
}

.selected {
  background-color: #f2f4f6 !important;
  transition: 0.4s all linear;
}

.action-header {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: flex-end;
}

.action-icon {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.action-icon img {
  width: 15px;
  height: 16px;
  transition: transform 0.3s ease;
}

.action-icon img:hover {
  transform: scale(1.05);
}

.arrow-icon-table {
  color: var(--arrow-icon-color) !important;
  font-size: 12px !important;
}

.page-heading-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.875rem 1rem 1.875rem;
}

.page-heading {
  color: var(--input-border-color);
  font-size: 14px;
}

.flex-check {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

/* checkbox design  */

.zoom-out-td {
  transition:
    transform 0.4s ease,
    color 0.4s ease;
  text-decoration: underline;
  color: 'black';
}

.zoom-out-td:hover {
  transform: scale(1.05);
  color: #0493ce;
  cursor: pointer;
  text-decoration: underline;
}

.zoom-out-help {
  transition:
    transform 0.3s ease,
    color 0.3s ease;
  height: 14px;
  width: 14px;
  stroke: 0.2;
  cursor: pointer;
}

.zoom-out-help:hover {
  transform: scale(1.05);
  color: #0493ce;
  cursor: pointer;
}

.state-container {
  margin-top: 9px;
  margin-left: 10px;
  height: 28px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.state-text {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}

.ntp-color {
  color: #ffa600;
}

.install-color {
  color: #117746;
}

.pto-color {
  color: #0493ce;
}

.ntp-bg {
  background-color: #fdf6e8;
}

.install-bg {
  background-color: #e1f3e4;
}

.pto-bg {
  background-color: #e4f6ff;
}

.ntp-width {
  width: 47px;
}

.install-width {
  width: 61px;
}

.pto-width {
  width: 48px;
}

/* Remove hover background color for the specific row */
table tbody tr:has(td[colspan='10'] .data-not-found):hover {
  background-color: transparent;
}

@media (max-width: 1024px) {
  .fildelb-btn {
    margin-right: -15px;
  }

  .iconsSection2 button > span {
    display: none;
  }

  .commissionSection h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .page-heading-container {
    flex-direction: column;
    justify-content: unset;
    gap: 1rem;
    padding: 20px;
  }

  .dashBoard-container {
    margin: 10px;
  }

  .commission-back {
    gap: 5px;
  }

  .iconContainer {
    gap: 10px;
  }

  .commissionSection h3 {
    text-overflow: ellipsis;
    overflow-x: hidden;
    text-wrap: nowrap;
    width: 130px;
  }
}
