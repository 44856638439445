.rep-Dashboard-section-container {
  width: 100%;
  height: 100%;
}
.rep-Dashboard-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1%;
  padding: 10px 0;
}
.rep-dashboard-payroll {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.rep-payroll-date {
  font-size: 13px;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  background: #ececec;
  padding: 4px 8px;
}
.rep-dash-head-input {
  display: flex;
  height: 47px;
  background-color: #ffffff;
  padding: 0 3px 0 3px; /*here change for reduce left space ***************/
  gap: 0;
  align-items: center;
  border-radius: 8px;
}
.rep-chart-view {
  background: #ffffff;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  padding: 8px;
  cursor: pointer;
  text-wrap: nowrap;
}
.rep-calendar-component2 {
  cursor: pointer;
  text-wrap: nowrap;
}
.rep-line-graph {
  display: flex;
  gap: 0.3rem;
}
.rep-filter-line {
  width: 36px !important;
  height: 36px !important;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    transform 0.3s ease,
    background-color 0.3s ease;
}
.rep-filter-line:hover {
  transform: scale(1.07);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.rep-active-filter-line {
  background-color: #377cf6;
}
.rep-active-filter-line img {
  fill: white;
}
.rep-filter-line img {
  width: 50%;
  height: 50%;
}
.rep-breakdown-container {
  padding-left: 1rem;
  background: #0096d3;
  height: auto;
}
.rep-breakdown-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0 1rem;
  background: #0096d3;
}
.rep-breakdown-img {
  cursor: pointer;
}
.rep-breakdown-table {
  display: block;
  height: 100%;
  overflow: auto;
  white-space: nowrap;
}
.rep-breakdown-table tbody {
  font-weight: 700;
}
.rep-transparent-model-down {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.rep-modal-down {
  background-color: #fefefe;
  border-radius: 16px;
  width: 40%;
  max-width: 900px;
  height: 650px;
  overflow: hidden;
}
.rep-modal-body-down {
  height: calc(100% - 70px);
  overflow-y: auto;
}
.rep-calender-container {
  position: absolute;
  z-index: 999;
  margin-left: -42px;
  top: 130%;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 0.4rem;
}
.rep-close-calender {
  background: #3d91ff;
  color: white;
  font-size: 12px;
  padding: 4px 15px;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  left: 410px;
  bottom: 10px;
  border: none;
}
.rep-reset-calender {
  background: #3d91ff;
  color: white;
  font-size: 12px;
  padding: 4px 15px;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  left: 400px;
  bottom: 10px;
  border: none;
}
.rep-teamImg {
  width: 80.43px;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.3);
}
.total-rep {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 1.2rem;
  width: 32%;
  height: 132px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: box-shadow 0.3s ease;
}
.total-rep:hover {
  box-shadow: 0px 4px 4px 0px rgba(72, 72, 72, 0.25);
}
.rep-total-section h4 {
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #ffffff;
}
.rep-total-section p {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #ffffff;
}
.rep-manage-user {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.rep-drop_label {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rep-inputLabel {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.date-inputLabel {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.rep-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.drop-d {
  margin-bottom: 4px;
}
.rep-white-back {
  background-color: white;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 18px;
}
.selected-indicator {
  display: none;
}
.date-range-small {
  display: none;
}

@media (max-width: 1024px) {
  .total-rep {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    padding: 1.2rem;
    width: 32.2%;
    height: 132px;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: box-shadow 0.3s ease;
  }
  .rep-total-section h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #ffffff;
    margin-right: 7px;
  }
  .rep-total-section p {
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #ffffff;
    margin-right: 7px;
  }
  .rep-teamImg {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.3);
  }
  .rep-manage-user {
    display: flex;
    gap: 0;
  }
  .rep-calender-container {
    position: absolute;
    z-index: 999;
    margin-left: -212px;
    top: 130%;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 0.4rem;
  }
  .rep-drop_label {
    width: 24px;
    height: 28px;
  }
  .rep-drop_label img {
    width: 15px;
    height: 14px;
  }
  .rep-active-filter-line {
    width: 32px;
    height: 32px;

    background-color: #377cf6;
  }
  .rep-active-filter-line img {
    fill: white;
    width: 18px;
    height: 15px;
  }
  .rep-Dashboard-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    padding: 10px 0;
  }
  .drop-d {
    margin-right: 0;
  }
  .rep-filter-line {
    width: 36px !important;
    height: 36px !important;
    border-radius: 0.5rem;
    cursor: pointer;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rep-up {
    margin-left: -1px;
  }
  .rep-dashboard-payroll {
    width: 110px;
  }
  .date-text {
    max-width: 10px; /* Adjust this value as needed */
  }
  .date-range-small {
    display: contents;
  }
  .date-range-large {
    display: none;
  }
}
