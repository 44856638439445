.customer_wrapper_list {
  flex-basis: calc(100%);
  border-radius: 16px;
  background-color: #fff;
  padding-top: 15px;
  padding-inline: 20px;
  max-width: auto;
  align-items: center;
  justify-content: center;
}

.createProfileTextView {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 15px 18px 15px;
  height: 100%;
}

.createProfileInputView {
  margin-top: 1rem;
}

.create_input_field_note {
  width: 100%;
}

.create_input_field_note textarea {
  width: 100%;
  border: 1px solid var(--input-border-color);
  outline: none;
  padding: 0.5rem;
  font-size: 12px !important;
  border-radius: 0.5rem;
}

.create_input_field_note textarea:hover,
.create_input_field_note textarea:focus {
  border-color: var(--primary-color);
}

.customer_wrapper_list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.customer_wrapper_list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.srs_new_create {
  position: relative;
  width: 49%;
  display: inline-block;
}

.salrep_input_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.react-tel-input .form-control {
  background-color: #f3f3f3 !important;
}

.main_head {
  font-weight: 600;
  color: var(--input-border-color);
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_head img {
  cursor: pointer;
  margin-right: 6px;
  transition: all 0.33s ease;
}

.main_head img:hover {
  transform: scale(1.11);
}

.an_head {
  margin-left: 15px;
  font-size: 18px;
  font-weight: 600;
  color: var(--input-border-color);
  margin-top: 4px;
}

.an_top {
  font-size: 18px;
  font-weight: 600;
}

.submitbut {
  width: 150px;
  height: 40px;
  background-color: #377cf6;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 450;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submitbut:hover {
  background-color: var(--button-hover-color);
  transform: scale(1.05);
}

.tenst_inp {
  padding-left: 10px;
  width: 94px;
  height: 38px;
  border-radius: 8px;
  background-color: #f3f3f3;
  border: none;
  margin-top: 22px;
}

.tenst_inp:focus {
  outline: none;
}

.tentaclesicons {
  position: absolute;
  top: 65%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  padding-left: 77px;
}

.tentaclesicons img {
  width: 12px;
  height: 12px;
  cursor: pointer;
  pointer-events: auto;
  transition: transform 0.3s ease;
}

.tentaclesicons img:hover {
  transform: scale(1.2);
}

.tentaclesicons img:first-child {
  margin-bottom: 2px;
}

.srActionButton {
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0 24px 0;
  gap: 1rem;
}

.mobileNumber {
  color: #4e4e4e;
  font-weight: 400;
}

.emailStyle {
  color: #4e4e4e;
  font-weight: 400;
}

.addresshead {
  color: #4e4e4e;
}

.form_header {
  margin-left: 14px;
}
@media (max-width: 768px) {
  .h_screen {
    margin-top: 10px;
  }
  .customer_wrapper_list {
    height: auto;
    margin-left: 0;
  }

  .form_header {
    margin-top: 10px;
  }

  .salrep_input_container {
    flex-direction: column;
    justify-content: flex-start;
  }

  .srs_new_create {
    width: 100%;
  }

  .submitbut {
    width: 95%;
  }

  .create_input_field_note textarea {
    width: 100%;
  }
}

.custom_label_newlead {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 4px;
}

.inputsearch {
  min-width: 220px !important;
  font-size: 12px;
  font-weight: 400;
  flex: 1 1;
  height: 2.37rem;
  color: var(--input-border-color);
  padding: 0px 14px;
  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  outline: 0;
}
.inputMap {
  position: absolute;
  left: 10px;
  top: 34px;
  color: #868686;
}

.inputsearch:hover {
  border-color: #377cf6;
  border-width: 1px;
}

.inputsearch:hover,
.inputsearch:focus,
.inputsearch:not(:placeholder-shown) {
  border-color: #377cf6;
  border-width: 1px;
}

.inputsearch::placeholder {
  color: var(--input-border-color);
}

.inputMap {
  fill: var(--input-border-color);
}

.inputsearch:focus::placeholder,
.inputsearch:hover::placeholder {
  color: var(--primary-color);
}

.inputsearch:hover + .inputMap {
  fill: var(--primary-color);
}

.focused + .inputMap,
.inputsearch.focused::placeholder {
  fill: var(--primary-color);
}
