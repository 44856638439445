.customer_wrapper_list {
  flex-basis: calc(100%);
  border-radius: 16px;
  background-color: #fff;
  max-width: 32em;
  width: 100%;
  margin-inline: 16px;
  padding: 4rem 0 2.5rem 0;
}

.transparent_lib {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .4);;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.success_not {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.succicon {
  height: 76px;
  width: 76px;
  background-color: #f55b5b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.succicon img {
  width: 100px;
  height: 100px;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success_not h2 {
  font-weight: 600;
  font-size: 18px;
  color: var(--input-border-color);
  font-family: 'Inter';
  margin-top: 5px;
}

.success_not p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--grey-text-color);
  max-width: 305px;
  text-align: center;
  font-family: 'Poppins';
}
.survey_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap: 12px;
}
.survey_button button {
  width: 150px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #377cf6;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  font-family: 'Poppins';
  transition: all 200ms ease;
}
.survey_button button:hover {
  transform: scale(1.05);
  background-color: #4062CA;
}
.survey_button .self {
  transition: all 0.3s ease;
  color: #377cf6;
  background-color: white !important;
  font-family: 'Poppins';
}

.other {
  background-color: #377cf6;
  transform: scale(1.03);
  font-family: 'Poppins';
  color: #ffffff;
}

.createUserCrossButton {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  cursor: pointer;
}

.createUserCrossButton img {
  transition: transform 0.3s ease;
}

.DetailsMcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 16px;
  border: 1px solid #377cf6;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #eef5ff;
}
.main_name {
  font-weight: 600;
  font-size: 18px;
  margin-top: -13px;
  margin-bottom: 3.7%;
  margin-left: -139px;
}

.mobileNumber {
  text-align: left;
  margin-left: -139px;
}
.addresshead {
  margin-bottom: 3.7%;
  margin-top: -15px;
}

@media (max-width: 768px){
  .survey_button button{
    width: 100px;
  }
}