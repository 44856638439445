.breadcrumb-container {
  display: flex;
  flex-direction: column;

  padding-bottom: 0.5rem;
}
.bread-link {
  display: flex;
  align-items: center !important;
  gap: 0.2rem;
}
.bread-link a {
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .breadcrumb-container {
    margin-top: 10px;
  }
}

@media screen and (max-width: 990px) {
  .breadcrumb-container {
    margin-left: 15px;
  }
}

@media screen and (max-width: 450px) {
  .schedule {
    margin-left: 16px;
    font-size: 18px;
    font-weight: 600;
  }
}
