.container {
    display: flex;
    gap: 20px;
    margin-right: 1.2rem;
  }

  .wrapper{
    background-color: #f5f5f5;
    /* height: calc(100vh - 218px);
    overflow-y: scroll; */
  }
  
  .column {
    flex: 1;
    max-width: 50%;
  }
  
  .card {
    background-color: white;
    border-radius: 28px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  
  .actions {
    display: flex;
    gap: 8px;
  }
  
  .editButton {
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    transition: all 0.3s ease;
  }
  
  .saveButton {
    background: #377CF6;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    transition: all 0.3s ease;
  }
  
  .cancelButton {
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    transition: all 0.3s ease;
  }

  .cancelButton:hover {
    background-color: #d5e4ff;
    transform: scale(1.05);
  }
  .editButton:hover {
    background-color: #d5e4ff;
    transform: scale(1.05);
  }
  .saveButton:hover {
    transform: scale(1.05);
  }
  
  
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 10px;
  }
  
  .field {
    margin-bottom: 12px;
  }
  
  .label {
    display: block;
    font-size: 12px;
    color: #565656;
    margin-bottom: 4px;
  }
  
  .inputDisabled {
    background: none;
    border: none;
  }
  
  .input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 24px;
    font-size: 12px;
    background: #F5F5FD;
  }
  
  .stringInput {
    width: 70%;
    padding: 8px;
    border: none;
    border-radius: 24px;
    font-size: 12px;
    background: #F5F5FD;
  }
  
  .select {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 24px;
    font-size: 12px;
    background: #F5F5FD;
  }
  
  .value {
    font-size: 12px;
    font-weight: 550;
    color: #333;
  }
  
  .mpptGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .mpptField {
    margin-bottom: 8px;
  }
  
  .mpptInputs {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
  }


  /* code for Scroll bar */

  .wrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  .wrapper::-webkit-scrollbar {
    display: initial;
  }
  
  .wrapper::-webkit-scrollbar-thumb {
    background-color: rgb(173, 173, 173);
    border-radius: 30px;
  }
  
  .wrapper::-webkit-scrollbar-button {
    display: initial;
  }
  
  .wrapper::-webkit-scrollbar {
    -ms-overflow-style: initial;
    scrollbar-width: thin;
  }
  
  .wrapper::-webkit-scrollbar-corner {
    background-color: transparent;
  }