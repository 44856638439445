.reset-input-wrap .input-wrapper .input-inner-view {
  border: none;
  border-bottom: 1px solid #d0d5dd;
  border-radius: unset !important;
}
.reset-input-wrap .input-wrapper .input-inner-view .input {
  color: #1a1a1a;
  font-size: 1rem;
  font-weight: 300;
}
@media screen and (max-width: 900px) {
  .reset-solar-sun {
    position: relative !important;
    bottom: 120% !important;
    right: -50% !important;
  }
}
@media screen and (max-width: 500px) {
  .reset-solar-sun {
    position: relative !important;
    bottom: 102% !important;
    right: -57% !important;
  }
}
