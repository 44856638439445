.changepass-change-password {
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  align-items: center;
  justify-content: center;
}

.changepass-cross-btn {
  position: absolute; /* Add this line */
  top: 12px; /* Adjust the top position as needed */
  left: 15px; /* Adjust the right position as needed */
  cursor: pointer; /* Add this line to indicate it's clickable */
}

.changepass-cross-btn img {
  width: 18.73px;
  height: 18.73px;
  color: #828282;
}

.changepass-change-password h2 {
  font-size: 28px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 700;
  line-height: 44px;
  margin-top: 10px;
}

.changepass-change-password p {
  font-size: 12px;
  margin-bottom: 28px;
  font-weight: 300;
  line-height: 10.9px;
  text-align: center;
}

.changepass-form-group {
  margin-bottom: 10px;
}

.changepass-button[type='submit'] {
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  align-self: center;
}

.changepass-button[type='submit']:hover {
  background-color: #1d66e8;
}

.logpass-button {
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #0056b3;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  align-self: center;
  font-weight: 400;
}

.logpass-button:hover {
  background-color: #f8f7f6;
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.remme {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #1a1a1a;
  margin-bottom: 4px;
}
.change-transparent-model {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.288);
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999;
  backdrop-filter: blur(6px);
}
