.h_screen {
  min-height: 100vh;
  display: flex;
}

.customer_wrapper_list {
  flex-basis: calc(100%);
  border-radius: 16px;
  background-color: #fff;
  padding-top: 30px;
  padding-inline: 20px;
  max-width: 950px;
  height: max-content;
  border: 1px solid #d8d8d8;
}

.customer_wrapper_list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.customer_wrapper_list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.srs_new_create {
  position: relative;
  width: 44%;
  display: inline-block;
}

.salrep_input_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.react-tel-input .form-control {
  background-color: #f3f3f3 !important;
}

.an_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'poppins', sans-serif;
  margin-left: 47px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: var(--input-border-color);
  margin-top: 4px;
  line-height: 24px;
  overflow-y: hidden;
}

.an_top {
  font-size: 18px;
  padding-left: 6px;
  padding-right: 38px;
  color: var(--input-border-color);
  font-weight: 600;
}

.submitbut {
  width: 150px;
  height: 40px;
  background-color: #377cf6;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.submitbut:hover {
  background-color: #0053e3;
  transform: scale(1.05);
}

.tenst_inp {
  padding-left: 13px;
  width: 94px;
  height: 34px;
  border-radius: 8px;
  border: 1px solid var(--input-border-color);
  margin-top: 22px;
  font-size: 12px;
  font-weight: 400;
}
.tenst_inp:focus {
  outline: none;
}

.tentaclesicons {
  position: absolute;
  top: 67%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  padding-left: 72px;
}

.tentaclesicons img {
  width: 9px;
  height: 9px;
  cursor: pointer;
  pointer-events: auto;
  transition: transform 0.3s ease;
}
.tentaclesicons img:hover {
  transform: scale(1.2);
}

.tentaclesicons img:first-child {
  margin-bottom: 2px;
}

.srActionButton {
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0 24px 0;
  gap: 1rem;
}

.custom_Input::placeholder {
  color: var(--input-border-color);
}

.custom_Input {
  font-size: 12px;
  font-weight: 400;
  flex: 1 1;
  height: 36px;
  color: var(--input-border-color);
  padding: 0px 14px;
  line-height: 18px;
  border: 0;
  border-radius: 0;
  outline: 0;
  width: 85% !important;
}

.custom_label {
  font-family: 'poppins', sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.innerView {
  width: 85% !important;
}

.scroll_user {
  padding-left: 19px;
}

.an_head p {
  padding-top: 6px;
  padding-left: 19px;
  padding-bottom: 9px;
}

.back_button {
  cursor: pointer;
}

.icon {
  height: 20px;
  width: 27px;
  font-weight: 600;
  transition: transform 0.2s ease;
}

.icon:hover {
  transform: scale(1.05);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 550px) {
  .custom_label {
    width: 210px;
    margin-top: 5px;
  }

  .an_head {
    line-height: 5px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .salrep_input_container.reverse_col {
    flex-direction: column-reverse !important;
  }
  .an_head p {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  .an_head {
    margin-left: 0px !important;
  }

  .salrep_input_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
  }
  .scroll_user {
    padding-left: 0px;
  }
  .inputView {
    padding-left: 0px !important;
    padding-right: 17px;
  }
  .innerView {
    width: 240% !important;
    border-radius: 8px;
  }
  .custom_Input {
    width: 230% !important;
    border-radius: 8px;
  }
  .submitbut {
    width: 97%;
  }
  .tentaclesicons {
    top: 71%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 600px) and (max-width: 820px) {
  .innerView {
    width: 100% !important;
  }
  .custom_Input {
    width: 100% !important;
  }
  .scroll_user {
    padding-left: 0px;
  }
  .an_head {
    margin-left: 30px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  body {
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

@media (max-width: 768px) {
  .h_screen {
    margin: 10px;
  }
  .an_top {
    font-size: 16px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .an_head {
    margin-bottom: 5px;
    margin-left: 17px;
  }
}
