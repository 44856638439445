.Deleteicons {
  cursor: pointer;
  height: 18px;
  width: 18px;
  color: var(--input-border-color);
  transition: all 0.3s ease;
}

.Deleteicons:hover {
  transform: scale(1.05);
}
.img_folder_view {
  margin-left: 8px;
}

.name_div {
  position: relative;
  cursor: pointer;
}

.name_hide {
  display: none;
  position: absolute;
  top: 175%;
  left: 70%;
  transform: translateX(-50%);
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 4px;
  z-index: 10;
}

.name_div:hover .name_hide {
  display: block;
}

.grid_date {
  padding-right: 43px;
}
.grid_icon {
  display: flex;
  justify-content: flex-end;
  margin-left: 276px;
}
