.comm-dropdown-container {
  position: relative;
  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  width: 180px;
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.comm-dropdown-container svg {
  color: var(--input-border-color);
}
.comm-dropdown-container:hover {
  border: 1px solid #377cf6;
  transform: scale(1.05);
}

.comm-dropdown-toggle {
  margin-top: 3px;
  cursor: pointer;
  padding: 4px 12px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  width: 11rem;
  min-height: unset;
  height: 30px;
  align-content: center;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #0493ce;
}

.comm-dropdown-toggle::after {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  color: #0493ce; /* Change this to the desired color */
}

.comm-toggle-text {
  color: var(--input-border-color);
  font-size: 12px;
}

.team-input-wrap input::placeholder {
  color: var(--grey-border-color);
}

.comm-dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 5;
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  margin-top: 6px;
  overflow-y: auto;
  width: 180px;
  height: 300px !important;
  box-sizing: border-box;
  color: var(--input-border-color);
  border: 1px solid var(--input-border-color);
  box-shadow: 0px 4px 10px rgba(43, 42, 42, 0.3);
}

.comm-dropdown-toggle:hover,
.comm-dropdown-toggle.active,
.comm-dropdown-container.active {
  border-color: var(--primary-color) !important;
  color: var(--primary-color);
}

.comm-dropdown-toggle:hover .comm-toggle-text,
.comm-dropdown-toggle.active .comm-toggle-text {
  color: var(--primary-color);
}

.comm-dropdown-toggle:hover svg,
.comm-dropdown-toggle.active svg {
  fill: var(--primary-color);
}

.comm-dropdown-item {
  display: flex;
  padding: 4px 8px;
  cursor: pointer;
  color: var(--input-border-color);
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 1px;
  display: flex;
  align-items: self-start;
}

.comm-drop-icon p {
  margin-top: -3px;
}

.comm-dropdown-item input[type='checkbox'] {
  margin-right: 8px;
  cursor: pointer;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  border: 1px solid var(--grey-text-color);
  margin-bottom: 7px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comm-dropdown-item input[type='checkbox']:checked {
  background-color: #ffffff;
}

.comm-dropdown-item input[type='checkbox']:checked::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: #377cf6;
  border-radius: 2px;
}

.comm-drop-icon {
  color: #0493ce;
  height: 120px;
  width: 20px;
  font-weight: 900;
}

.comm-dashboard-payroll {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.selected-text {
  color: #377cf6;
}

.selected-count {
  background-color: #377cf6;
  color: #ffffff;
  font-weight: 500;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-left: 12px;
}

.comm-dropdown-item:hover {
  background-color: #ddebff;
}

@media screen and (max-width: 767px) {
  .comm-dropdown-container {
    width: 100%;
  }
  .comm-dropdown-toggle {
    width: unset;
  }
  .comm-dropdown-menu {
    width: 100%;
    height: 70vh !important;
  }
}
